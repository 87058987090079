import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import imgproj1 from "../../Assets/Projects/proj1.jpeg";
import imgproj2 from "../../Assets/Projects/proj2.png";
import imgproj3 from "../../Assets/Projects/proj3.png";
import imgproj4 from "../../Assets/Projects/proj4.jpeg";
import imgproj5 from "../../Assets/Projects/proj5.jpeg";
import imgproj6 from "../../Assets/Projects/proj6.jpeg";
import imgproj7 from "../../Assets/Projects/proj7.png";
import imgproj8 from "../../Assets/Projects/proj8.png";
import imgproj9 from "../../Assets/Projects/proj9.png";
import imgproj10 from "../../Assets/Projects/proj10.png";
import imgproj11 from "../../Assets/Projects/proj11.png";
import imgproj12 from "../../Assets/Projects/proj12.png";
import imgproj13 from "../../Assets/Projects/proj13.png";
import imgproj14 from "../../Assets/Projects/proj14.png";
import imgproj15 from "../../Assets/Projects/proj15.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj1}
              isBlog={false}
              title="Daphner : Twitter Clone"
              description="Social website for people to share posts and interact with each other through chats, likes, comments and shares. Everyone has ability to customize his profile, as well as being notified once someone interact with his/her post. The platform is designed using Reactjs and Tailwindcss in the Frontend, while the Backend is developed with Django with batteries like Channels for websockets and real-time communication, SimpleJWT for handling authentication."
              ghLink="https://github.com/amhyou/Daphner-react"
              backend="https://github.com/amhyou/Daphner-django"
              demoLink="https://daphner.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj15}
              isBlog={false}
              title="Google Forms Clone"
              description="The project lets you create templates for forms to be shared and filled by anyone and see the results. Made possible by the C# Framework Blazor and using techs like Postgres, Redis and Minio to handle data storage."
              ghLink="https://github.com/amhyou/Forms"
              demoLink="https://forms.amhyou.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj8}
              isBlog={false}
              title="URL Shortener Service"
              description="A URL shortener service that transforms long URLs into concise, easy-to-share links. This service allows users to track clicks. The project is built using Laravel."
              ghLink="https://github.com/amhyou/url-shortner-laravel"
              // backend=""
              demoLink="https://short.amhyou.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj6}
              isBlog={false}
              title="Blog website"
              description="A simple blog to share articles from admin dashboard of the Django framework. The project is made possible by the Django framework."
              ghLink="https://github.com/amhyou/AffSite"
              demoLink="https://affsite.amhyou.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj10}
              isBlog={false}
              title="Expense Tracker"
              description="Desktop application to track expenses. The project is built using Tauri instead of Electronjs for its minimal binary."
              ghLink="https://github.com/amhyou/expense-tracker-tauri"
              // backend=""
              demoLink="https://media.amhyou.com/expense-tracker.exe"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj12}
              isBlog={false}
              title="Python Pro Agency"
              description="Landing page for an agency working with freelancers with python language. It contains a chat widget to interact with visitors and respond to their questions. The project is built using Nextjs."
              ghLink="https://github.com/amhyou/PydabaPro-Agency"
              // backend=""
              demoLink="https://pydaba-pro-agency.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj11}
              isBlog={false}
              title="King Domino Pygame"
              description="Game written by pygame library, compiled down to a windows binary by pyinstaller."
              ghLink="https://github.com/amhyou/expense-tracker-tauri"
              // backend=""
              demoLink="https://media.amhyou.com/king-domino.exe"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj5}
              isBlog={false}
              title="Support Chat"
              description="A simple implementation of the chat widget in websites using the module Django Channels to handle web sockets. The admin dashboard shows all active and disconnected users and an interactive input chat to send real-time messages to users. This project is a simpler version of the support chat services and needs a database and team management tools to be good."
              ghLink="https://github.com/amhyou/supportchat-react"
              backend="https://github.com/amhyou/supportchat-django"
            // demoLink="y" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj9} // Replace with the path to your image
              isBlog={false}
              title="Hosting Seller Telegram Bot"
              description="A Telegram bot that facilitates buying hosting accounts using usdt. Users can easily purchase hosting services. The bot is built using Rust and Redis database, ensuring high performance and security, with seamless integration for cryptocurrency transactions."
              ghLink="https://github.com/amhyou/tg-rust-panels"
              // backend=""
              demoLink="https://t.me/CpanelSeller_bot"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj3}
              isBlog={false}
              title="Kivy Puzzles"
              description="Collection of levels crafted to try hard to use the mind by solving some obstacles that require out of the box ideas. This project developed with the cross-platform python framework Kivy, that can be exported to run in any platform: Android, IOS, Windows, Ubuntu, MacOS."
              ghLink="https://github.com/amhyou/SolveMe"
              demoLink="https://media.amhyou.com/solve-me.apk"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj4}
              isBlog={false}
              title="DimaKoora"
              description="A website application that shows everyday football matches, and when the match starts it streams it live through Http protocol. The app works automatically thanks to a periodic function that scrapes matches information and update today’s matches and schedule the beginning of the streaming as well as notify people on the Telegram channel."
              ghLink="https://github.com/amhyou/DimaKoora"
              demoLink="https://koora.amhyou.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj2}
              isBlog={false}
              title="Movie Suggester App"
              description="A mobile application built with Expo and React Native that suggests movies. It features a caching layer using Go and Redis to cache data from the Open Movie Database for improved performance and reliability."
              ghLink="https://github.com/amhyou/MovieSuggester-ReactNative"
              demoLink="https://media.amhyou.com/movie-suggester.apk"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj7}
              isBlog={false}
              title="Four Digit Guessing Game"
              description="Multiplayer game where players compete to guess each other's four-digit numbers. The first player to correctly guess an opponent's number declared the winner. The game interface is designed with SvelteKit and Tailwindcss, and the backend is powered by Golang with WebSockets for real-time interaction ensuring robust and scalable performance, and SurrealDB for data persistence."
              ghLink="https://github.com/amhyou/fourdigitgame-sveltekit"
              backend="https://github.com/amhyou/fourdigitgame-golang"
              demoLink="https://fourdigitgame.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj13}
              isBlog={false}
              title="Entry Validation Platform"
              description="Platform designed to validate or check entries with real-time results. This tool allows backend workers to process and validate entries, providing instant feedback through WebSockets. Built with Vue.js and TailwindCSS for a sleek and responsive UI, the backend is powered by FastAPI."
              ghLink="https://github.com/amhyou/motion-checking-Vuejs"
              backend="https://github.com/amhyou/motion-checking-Fastapi"
              demoLink="https://motion.amhyou.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imgproj14}
              isBlog={false}
              title="QR Code Generator"
              description="A mobile application designed to generate QR codes from user inputs. The application is built with Flutter, providing a smooth and responsive user experience across both iOS and Android platforms. Users can easily create and share QR codes, with eventual additional features for saving and managing generated codes."
              ghLink="https://github.com/amhyou/qr_generator-Flutter"
              demoLink="https://media.amhyou.com/qr-generator.apk"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
